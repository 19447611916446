<template>
  <b-form-group :label="title">
    <v-select
      :options="options"
      @search="fetchOptions"
      label="value"
      v-model="currentCode"
      :placeholder="placeholder"
      :searchable="isSearchable"
      :clearable="true"
      :reduce="item => item.key"
      v-on:option:selected="loadDetail"
    >
      <template #option="{ key, value }">
        <em>{{ key }} - {{ value }}</em>
      </template>
      <template #selected-option="{ key, value }">
        <em>{{ key }} - {{ value }}</em>
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

export default {
  name: 'SelectKeyValue',
  model: {
    prop: 'code',
    event: 'input',
  },
  props: {
    options: Array,
    searchFunction: Function,
    code: String,
    placeholder: String,
    title: String,
  },
  computed: {
    isSearchable() {
      return isNotEmpty(this.searchFunction);
    },
    currentCode: {
      get() {
        return this.code;
      },
      set(code) {
        // console.log('updateSelf', code);
        this.$emit('input', code);
      },
    },
  },
  methods: {
    fetchOptions(search, loading) {
      if (this.searchFunction) {
        this.searchFunction(search, loading);
      }
    },
    loadDetail(value) {
      console.log('loadDetail', value);
      this.$emit('option:selected', value.key);
      this.$emit('option:description', value.value);
    },
  },
};
</script>

<style scoped>

</style>
